import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import SignUp from "./SignUp";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import ForgetPassword from "./ForgetPassword";
import Seminar from "./Seminar";
import Close from "./Close";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Close} />
          <PrivateRoute exact path="/seminar/:id" component={Close} />
          <Route exact path="/signup" component={Close} />
          <Route exact path="/login" component={Close} />
          <Route exact path="/forgetpassword" component={ForgetPassword} />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
