const BOOTH_TO_COMPANY_ID_STG = {
  "CRmcAUhLineF9Kf9vUB6QuhDPnq1":"CRmcAUhLineF9Kf9vUB6QuhDPnq1",
  "E2sHLcRoADYYl3jlU8fYDpHH6xz1":"E2sHLcRoADYYl3jlU8fYDpHH6xz1",
  "5jlccWtpOYX3KiNAmkwYeYdMn4o1":"5jlccWtpOYX3KiNAmkwYeYdMn4o1",
  "2iohL4LrPGg6Si4ct0FeQYkxj602":"2iohL4LrPGg6Si4ct0FeQYkxj602",
  "9ZO8bkrmDYa9JaXbYyIQaAuvtDr1":"9ZO8bkrmDYa9JaXbYyIQaAuvtDr1",
  "2DisGGAXWQPaAIvspdbDguQzd673":"2DisGGAXWQPaAIvspdbDguQzd673",
  "v01Hy1fv86YFgy68GZifwVv1G1i1":"v01Hy1fv86YFgy68GZifwVv1G1i1",
  "JHRFM8s8NseR1YCOeuCA7a0nArx2":"JHRFM8s8NseR1YCOeuCA7a0nArx2",
  "WSGJy9TRVzfFZoq0tCCUgcxPVy33":"WSGJy9TRVzfFZoq0tCCUgcxPVy33",
  "pUBWfirSiIXMll9x5HGCuRhymS63":"pUBWfirSiIXMll9x5HGCuRhymS63",
  "TbMZbLdurNOkaPACrA3UhBSZD1l1":"TbMZbLdurNOkaPACrA3UhBSZD1l1",
  "ywJLIoyKQ5MN6XaGekDxpqNb5m52":"ywJLIoyKQ5MN6XaGekDxpqNb5m52",
  "gfbvG4PELxedXSt02fumG6tR2JE2":"gfbvG4PELxedXSt02fumG6tR2JE2",
  "BeAlcPUdLnYZEN0zO3JCgolesiC3":"BeAlcPUdLnYZEN0zO3JCgolesiC3",
  "dY4MB68VjhMoiYNw6X15ohEbTof2":"dY4MB68VjhMoiYNw6X15ohEbTof2",
  "IFeLib1sgDhL2BkzQEh3Wd655TG3":"IFeLib1sgDhL2BkzQEh3Wd655TG3",
  "kiKiXdZyOLfpEYkpqnM0bKivYEJ3":"kiKiXdZyOLfpEYkpqnM0bKivYEJ3",
  "f1vqOIhT4zN4itdv5xACU5TjgaW2":"f1vqOIhT4zN4itdv5xACU5TjgaW2",
  "Ao1hxtAdDRbViY0FD9ah5XZn6bd2":"Ao1hxtAdDRbViY0FD9ah5XZn6bd2",
  "sSnqyXDVGjUiPrml59gYYSLE0Gd2":"sSnqyXDVGjUiPrml59gYYSLE0Gd2",
  "WB7Qmw6s8PgknE12BD2gn6syVzj1":"WB7Qmw6s8PgknE12BD2gn6syVzj1",
  "so3QL9b4RFe5HZChGp6UMLB2uSO2":"so3QL9b4RFe5HZChGp6UMLB2uSO2",
  "d2cr3YvgCpZmAzYi8bTE4puVb1l1":"d2cr3YvgCpZmAzYi8bTE4puVb1l1",
  "O9tlM8t1zehEdpbsqvuqJUEv3UE2":"O9tlM8t1zehEdpbsqvuqJUEv3UE2",
  "Nbw55JBoZqQsogNhtW88fVxuGMv1":"Nbw55JBoZqQsogNhtW88fVxuGMv1",
  "PBpbpvjjwDYzIt1UF8Lz38gibLs1":"PBpbpvjjwDYzIt1UF8Lz38gibLs1",
  "zczjfZhdOGfUnhAsAPHURxoxfX62":"zczjfZhdOGfUnhAsAPHURxoxfX62",
  "wh7hvCqJg0SOn0wELLZou8v8Sfj1":"wh7hvCqJg0SOn0wELLZou8v8Sfj1",
  "o9GFVw3JVAUDOMXBcK82ZhXConl2":"o9GFVw3JVAUDOMXBcK82ZhXConl2",
  "8xCULnPhqIWfcImFPZOEUCkXYtw2":"8xCULnPhqIWfcImFPZOEUCkXYtw2",
  "yq75LS244DSXTqU2YJwv8FkOfvR2":"yq75LS244DSXTqU2YJwv8FkOfvR2",
  "3Vhr7L3jF2awyEnROkKih7qG5RD2":"3Vhr7L3jF2awyEnROkKih7qG5RD2",
  "KQKcCxCjHbSilDtDIRhFaqoRqDf1":"KQKcCxCjHbSilDtDIRhFaqoRqDf1",
  "MGq8gY5C1Bf4rmd6Ez1wF7Ll8QI2":"MGq8gY5C1Bf4rmd6Ez1wF7Ll8QI2",
  "PICPTYQWsea5lNpIcHnrq3yRDqG2":"PICPTYQWsea5lNpIcHnrq3yRDqG2",
  "AmSPFdqQaWVdedb4qVMPCPh0bs42":"AmSPFdqQaWVdedb4qVMPCPh0bs42",
  "WVtI4BkCFSQpueLPgO6GaOr5m5m1":"WVtI4BkCFSQpueLPgO6GaOr5m5m1",
  "r8g36XQMlKUT7eI3BaTsnwfDj2W2":"r8g36XQMlKUT7eI3BaTsnwfDj2W2",
  "M8DMgA4phQe9UZX5LCpZ8rs7YZu2":"M8DMgA4phQe9UZX5LCpZ8rs7YZu2",
  "6IIEE50re7YJy11eLrAppH0pKNe2":"6IIEE50re7YJy11eLrAppH0pKNe2",
};

const BOOTH_TO_COMPANY_ID_PROD = {
  "CRmcAUhLineF9Kf9vUB6QuhDPnq1":"CRmcAUhLineF9Kf9vUB6QuhDPnq1",
  "E2sHLcRoADYYl3jlU8fYDpHH6xz1":"E2sHLcRoADYYl3jlU8fYDpHH6xz1",
  "5jlccWtpOYX3KiNAmkwYeYdMn4o1":"5jlccWtpOYX3KiNAmkwYeYdMn4o1",
  "2iohL4LrPGg6Si4ct0FeQYkxj602":"2iohL4LrPGg6Si4ct0FeQYkxj602",
  "9ZO8bkrmDYa9JaXbYyIQaAuvtDr1":"9ZO8bkrmDYa9JaXbYyIQaAuvtDr1",
  "2DisGGAXWQPaAIvspdbDguQzd673":"2DisGGAXWQPaAIvspdbDguQzd673",
  "v01Hy1fv86YFgy68GZifwVv1G1i1":"v01Hy1fv86YFgy68GZifwVv1G1i1",
  "JHRFM8s8NseR1YCOeuCA7a0nArx2":"JHRFM8s8NseR1YCOeuCA7a0nArx2",
  "WSGJy9TRVzfFZoq0tCCUgcxPVy33":"WSGJy9TRVzfFZoq0tCCUgcxPVy33",
  "pUBWfirSiIXMll9x5HGCuRhymS63":"pUBWfirSiIXMll9x5HGCuRhymS63",
  "TbMZbLdurNOkaPACrA3UhBSZD1l1":"TbMZbLdurNOkaPACrA3UhBSZD1l1",
  "ywJLIoyKQ5MN6XaGekDxpqNb5m52":"ywJLIoyKQ5MN6XaGekDxpqNb5m52",
  "gfbvG4PELxedXSt02fumG6tR2JE2":"gfbvG4PELxedXSt02fumG6tR2JE2",
  "BeAlcPUdLnYZEN0zO3JCgolesiC3":"BeAlcPUdLnYZEN0zO3JCgolesiC3",
  "dY4MB68VjhMoiYNw6X15ohEbTof2":"dY4MB68VjhMoiYNw6X15ohEbTof2",
  "IFeLib1sgDhL2BkzQEh3Wd655TG3":"IFeLib1sgDhL2BkzQEh3Wd655TG3",
  "kiKiXdZyOLfpEYkpqnM0bKivYEJ3":"kiKiXdZyOLfpEYkpqnM0bKivYEJ3",
  "f1vqOIhT4zN4itdv5xACU5TjgaW2":"f1vqOIhT4zN4itdv5xACU5TjgaW2",
  "Ao1hxtAdDRbViY0FD9ah5XZn6bd2":"Ao1hxtAdDRbViY0FD9ah5XZn6bd2",
  "sSnqyXDVGjUiPrml59gYYSLE0Gd2":"sSnqyXDVGjUiPrml59gYYSLE0Gd2",
  "WB7Qmw6s8PgknE12BD2gn6syVzj1":"WB7Qmw6s8PgknE12BD2gn6syVzj1",
  "so3QL9b4RFe5HZChGp6UMLB2uSO2":"so3QL9b4RFe5HZChGp6UMLB2uSO2",
  "d2cr3YvgCpZmAzYi8bTE4puVb1l1":"d2cr3YvgCpZmAzYi8bTE4puVb1l1",
  "O9tlM8t1zehEdpbsqvuqJUEv3UE2":"O9tlM8t1zehEdpbsqvuqJUEv3UE2",
  "Nbw55JBoZqQsogNhtW88fVxuGMv1":"Nbw55JBoZqQsogNhtW88fVxuGMv1",
  "PBpbpvjjwDYzIt1UF8Lz38gibLs1":"PBpbpvjjwDYzIt1UF8Lz38gibLs1",
  "zczjfZhdOGfUnhAsAPHURxoxfX62":"zczjfZhdOGfUnhAsAPHURxoxfX62",
  "wh7hvCqJg0SOn0wELLZou8v8Sfj1":"wh7hvCqJg0SOn0wELLZou8v8Sfj1",
  "o9GFVw3JVAUDOMXBcK82ZhXConl2":"o9GFVw3JVAUDOMXBcK82ZhXConl2",
  "8xCULnPhqIWfcImFPZOEUCkXYtw2":"8xCULnPhqIWfcImFPZOEUCkXYtw2",
  "yq75LS244DSXTqU2YJwv8FkOfvR2":"yq75LS244DSXTqU2YJwv8FkOfvR2",
  "3Vhr7L3jF2awyEnROkKih7qG5RD2":"3Vhr7L3jF2awyEnROkKih7qG5RD2",
  "KQKcCxCjHbSilDtDIRhFaqoRqDf1":"KQKcCxCjHbSilDtDIRhFaqoRqDf1",
  "MGq8gY5C1Bf4rmd6Ez1wF7Ll8QI2":"MGq8gY5C1Bf4rmd6Ez1wF7Ll8QI2",
  "PICPTYQWsea5lNpIcHnrq3yRDqG2":"PICPTYQWsea5lNpIcHnrq3yRDqG2",
  "AmSPFdqQaWVdedb4qVMPCPh0bs42":"AmSPFdqQaWVdedb4qVMPCPh0bs42",
  "WVtI4BkCFSQpueLPgO6GaOr5m5m1":"WVtI4BkCFSQpueLPgO6GaOr5m5m1",
  "r8g36XQMlKUT7eI3BaTsnwfDj2W2":"r8g36XQMlKUT7eI3BaTsnwfDj2W2",
  "M8DMgA4phQe9UZX5LCpZ8rs7YZu2":"M8DMgA4phQe9UZX5LCpZ8rs7YZu2",
  "6IIEE50re7YJy11eLrAppH0pKNe2":"6IIEE50re7YJy11eLrAppH0pKNe2",
};

// "入稿サイトの企業ユーザーFirebase authentication UID": "展示会サイトの企業ユーザーFirebase authentication UID"
export const BOOTH_TO_COMPANY_ID = /production/.test(
  process.env.REACT_APP_REAL_TIME_DATABASE_PATH_PREFIX
)
  ? BOOTH_TO_COMPANY_ID_PROD
  : BOOTH_TO_COMPANY_ID_STG;

export const STORE_KEY_BOOTH = "store-key-booth-id";
export const STORE_KEY_REDIRECT_PATH = "store-key-redirect-path";

const SEMINARS_STG = [
  "eAYoQA6m23bzI0wBCOmiwmmZLAh2",
  "Nbw55JBoZqQsogNhtW88fVxuGMv1",
];

const SEMINARS_PROD = [
  "eAYoQA6m23bzI0wBCOmiwmmZLAh2",
  "Nbw55JBoZqQsogNhtW88fVxuGMv1",
];

export const SEMINARS = /production/.test(
  process.env.REACT_APP_REAL_TIME_DATABASE_PATH_PREFIX
)
  ? SEMINARS_PROD
  : SEMINARS_STG;

export const SEMINAR_CONTENTS = [
  {
    id: 1,
    title:
      "大規模AIモデルの登場が変えるAIと社会と人間の関係　～自律型AIのためのプランニング～",
    speakers: [
      {
        id: 1,
        photo: "https://jpn-expo.com/wp-content/themes/twentytwenty/images/lecture-person-3.png",
        company: "慶應義塾大学理工学部　教授",
        role: "一般社団法人　人工知能学会　副会長",
        name: "栗原 聡 教授",
      },
    ],
    video: { id: "771441085", url: "https://player.vimeo.com/video/771441085" },
  },
  {
    id: 2,
    title:
      "ものづくりを変革するAI設計テクノロジー　～材料費高騰対策を含む自動化による設計最適化手法～",
    speakers: [
      {
        id: 2,
        photo: "https://jpn-expo.com/wp-content/themes/twentytwenty/images/lecture-person-9.png",
        company: "オートデスク株式会社",
        role: "営業マネージャー",
        name: "藤村 祐爾 氏",
      },
    ],
    video: { id: "771439982", url: "https://player.vimeo.com/video/771439982" },
  },
];
